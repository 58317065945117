import {
  VerifyIdErrorCodes, FaceMatchErrorCodes, Au10tixErrorCodes, AgePredictionErrorCodes,
} from 'types/logger';

/* eslint-disable max-len */
export default {
  invalidRequest: 'Ungültige Anfrage',
  pageNotFound: 'Seite nicht gefunden',
  and: 'und',
  or: 'oder',
  instruction: {
    useIdOrLicense: 'Bitte verwende einen offiziellen Führerschein oder Personalausweis.',
  },
  loadingPage: {
    title: 'Wir richten den sicheren und privaten Verifizierungsprozess ein',
    complete: 'FERTIG!',
    personal: 'Deine persönlichen Daten werden niemals gesammelt, gespeichert oder weitergegeben.',
    init: 'Initialisierung',
    pleaseWait: 'Bitte warte, bis der Initialisierungsprozess abgeschlossen ist.',
    safari: 'Der Initialisierungsprozess kann bei iOS Safari länger dauern.',
  },
  entry: {
    title: 'Gesicherte Altersverifizierung',
    usingAI: 'Mithilfe fortschrittlicher KI versuchen wir, dein Alter zunächst anhand deines Aussehens zu verifizieren.',
    noBio: 'Es werden keine biometrischen Daten übermittelt oder gespeichert.',
    ifUnsuccessful: 'Sollte dies nicht der Fall sein, leiten wir dich durch einen sicheren, anonymen ID-Verifizierungsprozess.',
    start: 'Verifizierung starten',
  },
  cameraEntry: {
    title: 'Fangen wir mit deiner Verifizierung an',
    firstAttempt: 'Wir versuchen zunächst, dein Alter durch einen schnellen Selfie-Scan mit Hilfe von KI-Technologie zu verifizieren.',
    noBio: 'Es werden keine biometrischen Daten übertragen oder gespeichert',
    ready: 'Bist du bereit fortzufahren?',
    allowCamera: 'Kamerazugriff erlauben',
  },
  prepareIdVerification: {
    title: 'Glückwunsch zu deinem jugendlichen Aussehen!',
    description: 'Wir benötigen etwas mehr, um dein Alter zu verifizieren, also benutze bitte einen amtlichen Ausweis oder Reisepass.',
    restEasy: 'Keine Sorge. Deine persönlichen Daten sind bei uns immer geschützt.',
    continue: 'Weiter',
  },
  footer: {
    title: 'Verifiziert von',
    terms: 'Allgemeine Geschäftsbedingungen',
    privacy: 'Datenschutzerklärung',
    biometric: 'Biometrisch',
    copyright: 'Urheberrecht',
    rights: 'Alle Rechte vorbehalten.',
  },
  error: {
    mintTag: 'Fehler beim Minting-Tag',
    generic: 'Etwas ist schiefgelaufen. Bitte versuche es erneut.',
    noFileSelected: 'Keine Datei ausgewählt',
    dynamic: {
      [VerifyIdErrorCodes.ERR_LOAD_IMAGE]: 'Etwas ist schiefgelaufen. Bitte versuche es erneut.',
      [VerifyIdErrorCodes.ERR_DETECT_NONE]: 'Es konnte kein Ausweis auf dem bereitgestellten Foto gefunden werden. Bitte vergewissere dich, dass das Foto einen eindeutigen und gültigen Personalausweis zur Identifizierung enthält.',
      [VerifyIdErrorCodes.ERR_DETECT_MULTIPLE]: 'Es wurden mehrere Personalausweise erkannt, bitte lege nur einen Personalausweis zur Identifizierung vor.',
      [VerifyIdErrorCodes.ERR_DETECT_MUGSHOT]: 'Auf dem Ausweis wurde kein zur Identifizierung geeignetes Foto gefunden. Bitte versuche es erneut.',
      [VerifyIdErrorCodes.ERR_DETECT_DATE]: 'Die Daten auf deinem Foto konnten nicht verifiziert werden. Bitte lade ein neues Foto hoch und vergewissere dich, dass diese gut erkennbar sind.',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_THRESHOLD]: 'Keine Daten innerhalb der Altersschwelle entdeckt.',
      [VerifyIdErrorCodes.ERR_DETECT_FACE]: 'Fehler bei der Größenänderung des Ausweisfotos und der Gesichtserkennung',
      [VerifyIdErrorCodes.ERR_DETECT_DATE_FUTURE]: 'Es konnte kein Gültigkeitsdatum gefunden werden. Bitte versuche es erneut.',
      [FaceMatchErrorCodes.ERR_FACE_MATCH_ID]: 'Das Foto auf dem Ausweis entspricht nicht deinem Aussehen. Bitte versuche es erneut.',
      [FaceMatchErrorCodes.ERR_MATCHING_FACES]: 'Bei der Übereinstimmung deines Gesichts ist ein Fehler aufgetreten. Bitte versuche es erneut.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AGE]: 'Au10tix ist nicht mindestens 18 Jahre alt.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_AUTHENTIC]: 'Ausweis von Au10tix nicht echt.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_EXPIRED]: 'Ausweis von Au10tix ist abgelaufen.',
      [Au10tixErrorCodes.ERR_AU10TIX_FAIL_MAX_ATTEMPTS]: 'Au10tix hat die maximale Anzahl an Versuchen erreicht.',
      [AgePredictionErrorCodes.ERR_RECURSIVE_AGE_PREDICTION]: 'Fehler beim bestimmen des Alters.',
      [AgePredictionErrorCodes.ERR_SET_VIDEO_SOURCE]: 'Fehler beim Einstellen der Videoquelle. Bitte prüfe deine Kamera.',
      [AgePredictionErrorCodes.ERR_NO_FACE_TIMEOUT]: 'Alter konnte nicht bestimmt werden, da kein Gesicht erkannt wurde.',
    },
  },
  failure: {
    title: 'Verifizierung fehlgeschlagen!',
    errorMessage: 'Bei der Verifizierung deines Alters ist ein Problem aufgetreten. Bitte versuche es erneut.',
    tryAgain: 'Versuche es erneut',
    submitForReview: 'Zur manuellen Überprüfung einreichen',
    returnToSite: 'Zurück zur Website',
  },
  success: {
    title: 'Glückwunsch, dein Alter wurde erfolgreich verifiziert.',
    description: 'Dein Alter wurde erfolgreich verifiziert.',
    continueToSite: 'Weiter zur Website',
  },
  appeal: {
    processing: 'In Bearbeitung ...',
    confirm: {
      photo: {
        title: 'Foto bestätigen',
        description: 'Um mit der manuellen Überprüfung fortzufahren, bestätige bitte das Foto deines Gesichts weiter unten. Falls du mit dem aktuellen Bild nicht zufrieden bist, kannst du die Aufnahme wiederholen, um das bestmögliche Ergebnis zu erzielen.',
        confirmAndProceed: 'Bestätigen und fortfahren',
        retakeNewPhoto: 'Foto erneut aufnehmen',
        takePhoto: 'Foto aufnehmen',
        errorAndRetake: 'Fehler bei der Fotoaufnahme. Bitte Foto erneut aufnehmen.',
      },
      id: {
        title: 'Bestätige deinen Ausweis',
        description: 'Bestätige nun das Bild deines Ausweises. Stelle sicher, dass alle Details deutlich und lesbar sind. Solltest du der Meinung sein, ein anderes Foto wäre besser geeignet, kannst du jetzt ein neues Foto hochladen.',
        confirmAndProceed: 'Bestätigen und fortfahren',
        uploadNewIdPhoto: 'Neues Ausweisfoto hochladen',
        uploadPhoto: 'Foto hochladen',
      },
    },
    success: {
      title: 'Erfolgreich',
      description: 'Vielen Dank! Wir haben deine Informationen erhalten und werden uns schnellstmöglich bei dir melden.',
    },
    manualSubmission: {
      title: 'Manuelle Verifizierung einreichen',
      description: 'Deine biometrischen Daten werden lediglich zur Altersverifizierung verwendet, wie in unseren Allgemeinen Geschäftsbedingungen und unserer Datenschutzrichtlinie dargelegt.',
      submitForManualReview: 'Zur manuellen Überprüfung einreichen',
      emailAddress: 'E-Mail-Adresse',
      message: 'Nachricht (Optional)',
      agreedToTermsAndConditionsAndPrivacyPolicy: 'Wenn Sie dieses Kästchen ankreuzen, akzeptiere unsere',
      termsAndConditions: 'Allgemeinen Geschäftsbedingungen',
      privacyPolicy: 'Datenschutzerklärung',
    },
  },
  a10tix: {
    title: 'Identität verifizieren',
    pending: 'Verifizierung ausstehend',
    verificationPending: 'Verifizierung ausstehend',
    verificationPendingDescription: 'Das kann bis zu einer Minute in Anspruch nehmen.',
    takingLongTimeMessage: 'Das dauert länger als gewöhnlich. Um den Vorgang zu beschleunigen, kannst du ein neues Foto deines Ausweises hochladen. Achte bitte darauf, dass das Foto nicht verwackelt und gut belichtet ist.',
    uploadNewPhoto: 'Neues Foto hochladen',
    submit: 'Einreichen',
    takeAPhoto: 'Foto aufnehmen',
    takeADifferentPhoto: 'Ein anderes Foto aufnehmen',
  },
  cameraPermission: {
    description: 'Um diese Funktion zu nutzen, ist die Kameraberechtigung erforderlich.',
    denied: 'Scheint, als hätten Sie den Kamerazugriff für diese Webseite verweigert. Aktualisieren Sie deine Browsereinstellungen und lade die Seite erneut.',
    goBack: 'Zurück',
  },
  detectionMessage: {
    'Please move your face into the frame': 'Bring bitte dein Gesicht in den Rahmen',
    'Turn your head to the left': 'Dreh deinen Kopf nach links',
    'Turn your head to the right': 'Dreh deinen Kopf nach rechts',
    'Tilt your head up': 'Hebe deinen Kopf an',
    'Tilt your head down': 'Neige deinen Kopf nach unten',
    'Look straight into the camera': 'Schau direkt in die Kamera',
    'Move your head slowly to fill in the circle': 'Bewege deinen Kopf langsam und fülle den Kreis.',
  },
  reviewRequest: {
    title: 'Anfrage zur Überprüfung',
    email: 'E-Mail',
    message: 'Nachricht',
    idImage: 'Ausweisbild',
    userImage: 'Benutzerbild',
    requestApproved: 'Antrag genehmigt',
    requestDenied: 'Antrag abgelehnt',
    approve: 'Genehmigen',
    deny: 'Abgelehnen',
    processing: 'In Bearbeitung ...',
  },
  licenses: {
    title: 'Führerscheinfotos',
    loading: 'Wird geladen ...',
    deleteAll: 'Alle löschen',
  },
  license: {
    emblemState: 'Staatswappen:',
    ocrStatus: 'OCR-Status:',
    fileId: 'Datei-ID:',
    createdAt: 'Erstellt am:',
    uploadReason: 'Grund des Hochladens:',
    status: 'Status:',
    ocrFailureReason: 'Grund für OCR-Fehler:',
  },
  idPhotoGuidelines: {
    title: 'Bitte beachte Folgendes:',
    placeId: 'Lege den Ausweis auf eine flache, einfarbige Oberfläche.',
    ensureGlareFree: 'Achte auf ein blendfreies, gut belichtetes Foto.',
    allEdges: 'Alle Kanten des Ausweises müssen sich innerhalb des Rahmens befinden.',
  },
  takePhoto: {
    rotateGuide: 'Anleitung zum Drehen',
    setIdFlat: 'Vergewissere dich, dass der Ausweis auf einer ebenen Fläche liegt, gut sichtbar ist und sich alle Kanten innerhalb des Rahmens befinden.',
    cancel: 'Abbrechen',
  },
  uploadPhoto: {
    uploadPhoto: 'Foto hochladen',
  },
  verifyId: {
    noFaceDetected: 'Auf dem hochgeladenen Foto wurde kein Gesicht erkannt',
    idShouldBeVisible: ' Dein Ausweis sollte auf dem Foto vollständig sichtbar und lesbar sein.',
    submit: 'Einreichen',
    next: 'Weiter',
    retakePhoto: 'Foto erneut aufnehmen',
    rescanFace: 'Gesicht erneut scannen',
    verificationInProgress: 'Verifizierung wird durchgeführt ...',
    pleaseWait: 'Bitte warte, während dein Foto verifiziert wird. Dies sollte nur wenige Sekunden dauern.',
    chooseId: 'Wähle deinen Ausweistyp',
    idCard: 'Führerschein oder amtlicher Personalausweis',
    germanIdCard: 'Deutscher Personalausweis',
    passport: 'Reisepass',
    selectCountry: 'Wähle das Land aus, in dem dein Dokument ausgestellt wurde:',
    continue: 'Weiter',
    frontOfId: 'Mach ein Foto von der Vorderseite deines Ausweises',
    faceNoMatch: 'Das Gesicht im Ausweis stimmt nicht mit deinem Aussehen überein.',
    informationSecure: 'Deine Informationen bleiben sicher und vertraulich.',
    scanFailure: {
      title: 'Verifizierung nicht erfolgreich',
      unable: 'Es tut uns leid, aber wir konnten dein Alter anhand des vorgelegten Dokuments nicht verifizieren. Versuchen wir es erneut.',
    },
  },
  agePrediction: {
    settingUpCamera: 'Einrichtung deiner Kamera',
  },
  human: {
    errorInitializing: 'Fehler bei Initialisierung',
    errorLoadingModel: 'Fehler beim Laden des Modells',
    pleaseCloseWindowAndTryAgain: 'Bitte schließe dieses Fenster und versuche es erneut',
  },
  verifyIdInfo: {
    passport: {
      title: 'Scanne die Identifikationsseite deines Reisepasses',
      // @todo: atg translation needed
      // instruction: 'Reisepassanweisungen',
      instruction: [
        '• Dokument flach hinlegen und für gute Beleuchtung sorgen',
        '• Gesamtes Dokument im Rahmen erfassen',
        '• Code unten mit der Führung ausrichten',
      ].join('<br/>'),
    },
    'non-us-id': {
      title: 'Scanne die Rückseite deines Ausweises',
      // @todo: atg translation needed
      // instruction: 'Nicht-US-Ausweisanweisungen',
      instruction: [
        '• Dokument flach hinlegen und für gute Beleuchtung sorgen',
        '• Gesamtes Dokument im Rahmen erfassen',
        '• Code unten mit der Führung ausrichten',
      ].join('<br/>'),
    },
    'us-id': {
      title: 'Scanne die Rückseite deines Ausweises',
      instruction: 'US-Ausweisanweisungen',
    },
    scanBarcode: 'Diesen Barcode finden und scannen',
    cantFindBarcode: 'Du kannst auf deinem Ausweis keinen Barcode finden? Tippe auf „Überspringen“, um fortzufahren.',
    skipBarcode: 'Überspringen',
  },
  faceAngleMessage: {
    moveFace: 'Bring bitte dein <br /> Gesicht in den Rahmen',
    lookStraight: 'Schau direkt <br /> in die Kamera',
    turnHead: 'Dreh deinen Kopf',
    keepTurningClockwise: 'Weiter im Uhrzeigersinn drehen, <br /> um einen Kreis zu vollenden.',
  },
};
